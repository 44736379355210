import dynamic from 'next/dynamic';
import StyledFooter from './footer.styled';

const Info = dynamic(() => import('./Info'), { ssr: true });
const Links = dynamic(() => import('./Links'), { ssr: true });
const Foot = dynamic(() => import('./Foot'), { ssr: true });

const Footer = () => (
    <StyledFooter>
        <Info />
        <Links />
        <Foot />
    </StyledFooter>
);

export default Footer;
