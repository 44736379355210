import styled from 'styled-components';

const StyledFooter = styled.footer`
    padding-top: 7em;

    @media (max-width: 991px) {
        padding-top: 2em;
    }
`;

export default StyledFooter;
